type Transition = "slide" | "fade";

export class Navigation {
	observer: IntersectionObserver;
	headerNav: HTMLElement;
    navToggle: HTMLElement;
	navSheets: HTMLElement[];

	constructor() {
		this.headerNav = document.querySelector<HTMLElement>(".header-nav")!;
		if (this.headerNav == null) {
			throw new Error("Unable to find header navigation element #headerNav");
		};

		this.navToggle = this.headerNav.querySelector<HTMLElement>(".header-nav__toggle")!;
		if (this.navToggle == null) {
			throw new Error("Unable to find nav toggle.");
		};

		this.navSheets = Array.from(this.headerNav.querySelectorAll<HTMLElement>(".navigation-sheet"));

		this.headerNav.addEventListener("click", this.handleNavClick.bind(this));
		this.navToggle.addEventListener("click", this.handleNavToggleClick.bind(this));
	}

	showPersistentNavToggle() {
		this.navToggle.classList.add("header-nav__toggle--persistent");
	}
	hidePersistentNavToggle() {
		this.navToggle.classList.remove("header-nav__toggle--persistent");
	}

	handleNavToggleClick(e: MouseEvent) {
		if (this.navSheets.length === 0) {
			return;
		}

		let activeNavSheet = this.navSheets.find(x => x.classList.contains("navigation-sheet--active"));
		this.open(activeNavSheet ?? this.navSheets[0], "slide");
	}

	handleNavClick(e: MouseEvent) {
		let target = e.target as HTMLElement | null;
		if (target == null) {
			return;
		}

		let navItem = target.closest(".header-nav__item");
		if (navItem != null) {
			e.preventDefault();
			return this.onNavItemClick(navItem);
		}

		let closeButton = target.closest(".navigation-sheet__close");
		if (closeButton != null) {
			return this.onCloseSheetClick(closeButton);
		}
	}

	onNavItemClick(navItem: Element) {
		let sheetId = navItem.getAttribute("data-nav");
		if (sheetId == null) {
			return;
		}

		let sheet = document.getElementById(sheetId);
		if (sheet == null) {
			return;
		}

		let transition: Transition = "slide";
		let activeSheet = document.querySelector(".navigation-sheet.is-open");
		if (activeSheet != null) {
			transition = "fade";
			this.close(activeSheet, transition);
		}

		this.open(sheet, transition);
	}

	onCloseSheetClick(closeButton: Element) {
		let sheet = closeButton.closest(".navigation-sheet");
		if (sheet == null) {
			return;
		}

		for (let sheet of this.navSheets) {
			sheet.classList.remove("animation--fade");
			sheet.classList.add("animation--slide");
		}

		this.close(sheet, "slide");
	}

	open(sheet: Element, transition: Transition) {
		sheet.classList.remove("animation--fade", "animation--slide");
		sheet.classList.add("is-open", `animation--${transition}`);

		document.body.style.top = `-${window.scrollY}px`;
		document.body.classList.add("is-scroll-blocked");
	}

	close(sheet: Element, transition: Transition) {
		sheet.classList.remove("animation--fade", "animation--slide");
		sheet.classList.add(`animation--${transition}`);
		sheet.classList.remove("is-open");

		const scrollY = document.body.style.top;
		document.body.classList.remove("is-scroll-blocked");
		document.body.style.top = "";
		window.scrollTo(0, parseInt(scrollY || "0") * -1);
	}
}
