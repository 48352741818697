import { h } from "vue";
import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";

@Component({
	render: function(this: SvgIcon) {
		return h("svg",
			[
				h("use", { attrs: { "xlink:href": this.svgHref } })
			])
	}
})
export class SvgIcon extends Vue {
	@Prop() icon: string[];
	@Prop() size: string;

	get svgHref(): string {
		return `#icon:${this.icon[1]}`;
	}
}
