export function removeDiacritics(text: string): string {
	text = text
		.replace("ä", "ae")
		.replace("ö", "oe")
		.replace("ü", "ue")
		.replace("Ä", "ae")
		.replace("Ö", "oe")
		.replace("Ü", "ue");

	return text
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.normalize();
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
	return list.reduce((previous, currentItem) => {
		const group = getKey(currentItem);
		if (!previous[group]) previous[group] = [];
		previous[group].push(currentItem);
		return previous;
	}, {} as Record<K, T[]>);
}

export function isNullOrEmpty(value: string | null | undefined): value is "" | null | undefined {
	return value === null || value === undefined || value === "";
}
