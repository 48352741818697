import "../Styles/index.scss";
import "regenerator-runtime/runtime";
import "reflect-metadata";

import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);
if (browser.satisfies({ mobile: { safari: ">1" } })) {
	document.body.classList.add("is-mobile-safari");
}
if (browser.satisfies({ mobile: { samsung_internet: ">1" } })) {
	document.body.classList.add("is-mobile-samsunginternet");
}

import { createApp, defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { ConfigProgrammatic, ModalProgrammatic, Field, Input, Checkbox, Radio, Button, Select, Switch, Loading, Notification, Modal, Snackbar, Datepicker, Numberinput } from "buefy";
import { SvgIcon } from "./components/svg-icon";

function configureApp(app: App) {
	app.component("svg-icon", SvgIcon);

	app.use(Field);
	app.use(Input);
	app.use(Checkbox);
	app.use(Radio);
	app.use(Button);
	app.use(Select);
	app.use(Switch);
	app.use(Loading);
	app.use(Notification);
	app.use(Modal);
	app.use(Snackbar);
	app.use(Datepicker);
	app.use(Numberinput);
}

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp: configureApp,
	});
}

const modalDummyApp = createApp({});
configureApp(modalDummyApp);
const ModalStatic = new ModalProgrammatic(modalDummyApp);

ConfigProgrammatic.setOptions({
	defaultUseHtml5Validation: false,
	defaultIconPack: "icon",
	defaultIconComponent: "svg-icon",
});

if (document.querySelector("cta-form-trigger") != null) {
	import("./components/cta-form-trigger").then(m => {
		customElements.define("cta-form-trigger", defineAndConfigureCustomElement(m.CtaFormTrigger));
	});
}

if (document.querySelector("bluc-map") != null) {
	import("./components/bluc-map").then(m => {
		customElements.define("bluc-map", defineAndConfigureCustomElement(m.BlucMap));
	});
}

if (document.querySelector("point-of-sale-map") != null) {
	import("./components/point-of-sale-map").then(m => {
		customElements.define("point-of-sale-map", defineAndConfigureCustomElement(m.PointOfSaleMap));
	});
}

if (document.querySelector("team-list") != null) {
	import("./team/team-list").then(m => {
		customElements.define("team-list", defineAndConfigureCustomElement(m.TeamList));
	});
}

import { GlobalSearch } from "./search/global-search";
customElements.define("global-search", defineAndConfigureCustomElement(GlobalSearch));

import { Navigation } from "./navigation";
let navigation = new Navigation();

let navigationObserver = new IntersectionObserver(onObserverFired);

let headerNav = document.querySelector<HTMLElement>(".header-nav");
if (headerNav != null) {
	navigationObserver.observe(headerNav);
}

let contactOverlayButton = document.querySelector<HTMLElement>(".contact-overlay__button");
contactOverlayButton?.addEventListener("click", async e => {
	e.preventDefault();
	contactOverlayButton?.classList.add("is-loading");

	const { CustomForm } = await import("./custom-form/custom-form");
	ModalStatic.open({
		component: CustomForm,
		props: {
			formId: "8sLmNUoO8Eue6RgCO2hRn0ZybQ"
		}
	});
	document.documentElement.dispatchEvent(new CustomEvent("modal-opened"));

	try {
		plausible("Globales Kontaktformular geöffnet");
	} catch { }

	contactOverlayButton?.classList.remove("is-loading");
});

let searchButton = document.getElementById("searchButton");
searchButton?.addEventListener("click", e => {
	document.dispatchEvent(new CustomEvent("open-search"));
});

function onObserverFired(entries: IntersectionObserverEntry[]) {
	if (!entries[0].isIntersecting) {
		navigation.showPersistentNavToggle();
		contactOverlayButton?.classList.add("is-collapsed");
	} else {
		navigation.hidePersistentNavToggle();
		contactOverlayButton?.classList.remove("is-collapsed");
	}
}

async function registerFormHandlers(elements: NodeListOf<HTMLAnchorElement>) {
	const { CustomForm } = await import("./custom-form/custom-form");

	for (let element of elements) {
		let url = new URL(element.href);
		let formId = url.searchParams.get("form-id");
		element.addEventListener("click", e => {
			let modal = ModalStatic.open({
				component: CustomForm,
				props: {
					formId: formId,
					metadata: null
				},
				events: {
					close: () => modal.close()
				}
			});

			document.documentElement.dispatchEvent(new CustomEvent("modal-opened"));
			document.documentElement.addEventListener("modal-opened", e => {
				modal.close();
			}, { once: true });

			e.preventDefault();
		});
		element.href = "";
	}
}

let openFormLinks = document.querySelectorAll<HTMLAnchorElement>("a[href^='bluc:open-form']");
if (openFormLinks.length > 0) {
	registerFormHandlers(openFormLinks);
}

const domChangeObserver = new MutationObserver(records => {
	for (let node of records.flatMap(r => Array.from(r.addedNodes))) {
		if (node instanceof HTMLElement) {
			let addedOpenFormLinks = node.querySelectorAll<HTMLAnchorElement>("a[href^='bluc:open-form']");
			if (addedOpenFormLinks.length > 0) {
				registerFormHandlers(addedOpenFormLinks);
			}
		}
	}
});
domChangeObserver.observe(document.body, { childList: true, subtree: true });

import { Gallery } from "./components/gallery";
for (let element of document.querySelectorAll<HTMLElement>(".gallery-block")) {
	new Gallery(element);
}

import Flickity from "flickity";
let imageSliders = new Map<Element, Flickity>();
let imageSliderObserver = new IntersectionObserver(entries => {
	for (let entry of entries) {
		let flickity = imageSliders.get(entry.target);
		if (entry.isIntersecting) {
			flickity?.unpausePlayer();
		} else {
			flickity?.pausePlayer();
		}
	}
}, {
	threshold: [0.2, 1]
});

for (let element of document.querySelectorAll<HTMLElement>(".image-slider")) {
	let flickity = new Flickity(element, {
		setGallerySize: false,
		cellAlign: "left",
		pageDots: false,
		lazyLoad: true,
		autoPlay: 3000,
	});
	flickity.pausePlayer();
	imageSliders.set(element, flickity);
	imageSliderObserver.observe(element);
}

import mediumZoom from "@wagich/medium-zoom";
let zoom = mediumZoom(".is-zoomable:not(.gallery-block__image):not(.image-slider .asset-list__item)", {
	background: "rgba(255,255,255,0.66)",
	margin: Math.max(window.innerHeight, window.innerWidth) * 0.045, // 4.5vmax
});

function setCtaHeightCssVariable() {
	for (let ctaElement of document.querySelectorAll<HTMLElement>(".cta-block")) {
		ctaElement.style.setProperty("--height", `${ctaElement.clientHeight}px`);
	}
}
window.addEventListener("resize", setCtaHeightCssVariable);
window.addEventListener("load", setCtaHeightCssVariable);
