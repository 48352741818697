import { Vue, Component, Prop, Watch, Inject, Ref } from "@wagich/vue-facing-decorator-metadata";
import algoliasearch, { type SearchClient } from "algoliasearch";
import type { SearchOptions, SearchResponse, Hit } from "@algolia/client-search";
import insightsClient from "search-insights";
import debounce from "lodash/debounce";
import { nanoid } from "nanoid";

import { render } from "./global-search.html";
import type { SearchResultItem } from "./models";
import { isNullOrEmpty } from "../utils";

const UserTokenSessionStorageKey = "algoliaUserToken";

interface BInputShim {
	focus: () => void;
}

enum State {
	Initial = "initial",
	Busy = "busy",
	Results = "results",
	NoResults = "no-results",
}


@Component({
	render
})
export class GlobalSearch extends Vue {
	private _client: SearchClient;

	@Prop() apiKey: string;
	@Prop() applicationId: string;
	@Prop() indexName: string;
	@Ref() searchElement: BInputShim;

	isActive: boolean = false;
	isDirty: boolean = false;
	state: State = State.Initial;
	query: string = "";
	queryId: string = "";
	results: Hit<SearchResultItem>[] = [];

	get hasQuery(): boolean {
		return this.query.length > 0;
	}
	get hasResults(): boolean {
		return this.results.length > 0;
	}

	created() {
		let userToken = sessionStorage.getItem(UserTokenSessionStorageKey);
		if (userToken == null) {
			userToken = nanoid();
			sessionStorage.setItem(UserTokenSessionStorageKey, userToken);
		}

		this._client = algoliasearch(this.applicationId, this.apiKey);
		insightsClient("init", {
			appId: this.applicationId,
			apiKey: this.apiKey,
			userToken: userToken,
		});
	}

	mounted() {
		document.addEventListener("open-search", this.open.bind(this));
		document.addEventListener("keydown", this.onGlobalKeyDown.bind(this));
	}
	unmounted() {
		document.removeEventListener("open-search", this.open);
		document.removeEventListener("keydown", this.onGlobalKeyDown);
	}

	onGlobalKeyDown(e: KeyboardEvent) {
		if (e.key === "Escape") {
			this.close();
		}
	}

	open() {
		this.isActive = true;
		this.$nextTick(() => this.searchElement.focus());

		try {
			plausible("Suche geöffnet");
		} catch { }
	}
	close() {
		this.isActive = false;
	}

	clear() {
		this.query = "";
		this.results = [];
		this.state = State.Initial;
	}

	@Watch("isActive")
	onIsActiveChanged() {
		if (this.isActive === false) {
			this.isDirty = false;
			this.clear();
		}
	}

	@Watch("query")
	onQueryChanged(value: string) {
		if (!isNullOrEmpty(value)) {
			this.state = State.Busy;
			this.searchDebounced(value);
		} else {
			this.clear();
		}
	}

	readonly searchDebounced = debounce(this.search, 350);
	async search(query: string) {
		let response = await this._client.search<SearchResultItem>([{
			indexName: this.indexName,
			query: query,
			params: {
				clickAnalytics: true,
			}
		}]);
		let results = response.results[0] as SearchResponse<SearchResultItem>;
		this.results = results.hits;
		this.queryId = results.queryID!;

		if (this.hasResults) {
			this.state = State.Results;
		} else {
			this.state = State.NoResults;
		}

		this.isDirty = true;

		try {
			plausible("Suche durchgeführt");
		} catch { }
	}

	getContentSnippet(item: SearchResultItem): string {
		if (item._snippetResult && item._snippetResult.content) {
			return item._snippetResult.content.value;
		} else {
			return item.content;
		}
	}

	navigateToResult(result: Hit<SearchResultItem>, position: number) {
		insightsClient("clickedObjectIDsAfterSearch", {
			queryID: this.queryId,
			eventName: "Search result clicked",
			objectIDs: [result.objectID],
			positions: [position],
			index: this.indexName
		});
	}
}
