<b-modal class="global-search-modal" has-modal-card width="auto" scroll="keep" :trap-focus="true" v-model="isActive">
	<div class="modal-card global-search" :class="{ 'has-results': hasResults }">
		<header class="modal-card-head">
			<div class="modal-card-title">
				<b-input class="global-search__input" ref="searchElement" placeholder="Suchen nach …" icon="search" v-model="query" />
			</div>
		</header>
		<section class="modal-card-body" :class="{ 'is-flex-grow-0': !isDirty }">
			<b-loading :is-full-page="false" :active="state === 'busy'" />
			<transition name="slide">
				<div class="global-search__results" v-if="state === 'results'">
					<a class="global-search__result" :href="result.url" @click="navigateToResult(result, index + 1)" v-for="(result, index) in results">
						<p class="title global-search__result-title mb-0">{{result.title}}</p>
						<p class="global-search__result-snippet" v-html="getContentSnippet(result)" />
					</a>
				</div>
				<div class="global-search__no-results" v-if="state === 'no-results'">
					<p class="title is-5">Keine passenden Resultate gefunden.</p>
				</div>
			</transition>
		</section>
	</div>
</b-modal>
